<template>
  <div>
    <error-dialog
      message="Error! Try again."
      :showDialog.sync="errorDialog"
    ></error-dialog>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
export default {
  components: {
    ErrorDialog
  },
  data: () => ({}),
  computed: {
    ...mapState("accountStatus", ["statusesError"]),
    errorDialog: {
      get() {
        return this.statusesError;
      },
      set(newValue) {
        this.setStatusesError(newValue);
      }
    }
  },
  methods: {
    ...mapMutations("accountStatus", ["setStatusesError"]),
    ...mapActions("accountStatus", ["getBillingStatusTypes"])
  },
  async beforeMount() {
    this.setStatusesError(false);
    await this.getBillingStatusTypes();
  }
};
</script>
